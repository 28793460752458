exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-interests-jsx": () => import("./../../../src/pages/interests.jsx" /* webpackChunkName: "component---src-pages-interests-jsx" */),
  "component---src-pages-meditation-jsx": () => import("./../../../src/pages/meditation.jsx" /* webpackChunkName: "component---src-pages-meditation-jsx" */),
  "component---src-pages-newsletter-success-jsx": () => import("./../../../src/pages/newsletter-success.jsx" /* webpackChunkName: "component---src-pages-newsletter-success-jsx" */),
  "component---src-pages-printables-jsx": () => import("./../../../src/pages/printables.jsx" /* webpackChunkName: "component---src-pages-printables-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-australia-curriculum-substrands-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/Australia-curriculum-substrands/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-australia-curriculum-substrands-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-australian-curriculum-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/Australian-curriculum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-australian-curriculum-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-avg-score-on-they-did-the-math-subreddit-by-keyword-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/avg-score-on-they-did-the-math-subreddit-by-keyword/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-avg-score-on-they-did-the-math-subreddit-by-keyword-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-brainwaves-post-1-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/brainwaves-post-1/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-brainwaves-post-1-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-chat-gpt-first-experiments-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/chatGPT-first-experiments/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-chat-gpt-first-experiments-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-difficulty-first-fleet-project-observation-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/difficulty/first-fleet-project-observation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-difficulty-first-fleet-project-observation-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-edu-resource-data-post-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/edu-resource-data-post/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-edu-resource-data-post-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-adjectives-adjectives-captainplanet-sailormoon-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/fandoms/adjectives/adjectives-captainplanet-sailormoon/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-adjectives-adjectives-captainplanet-sailormoon-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-adjectives-batman-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/fandoms/adjectives/batman/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-adjectives-batman-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-set-math-marvel-heroes-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/fandoms/SetMath/MarvelHeroes/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-set-math-marvel-heroes-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-sonic-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/fandoms/sonic/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-fandoms-sonic-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-history-rejoice-our-math-skills-have-risen-since-1861-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/history/Rejoice-our-math-skills-have-risen-since-1861/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-history-rejoice-our-math-skills-have-risen-since-1861-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-learning-machine-learning-index-2-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/learning-machine-learning/index-2.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-learning-machine-learning-index-2-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-learning-machine-learning-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/learning-machine-learning/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-learning-machine-learning-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-meditation-anxiety-cure-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/meditation/anxiety-cure/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-meditation-anxiety-cure-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-meditation-my-quick-before-and-after-test-physics-meditations-my-quick-before-and-after-test-physics-meditations-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/meditation/my-quick-before-and-after-test-physics-meditations/my-quick-before-and-after-test-physics-meditations.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-meditation-my-quick-before-and-after-test-physics-meditations-my-quick-before-and-after-test-physics-meditations-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-top-3-complaints-about-math-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/top-3-complaints-about-math/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-top-3-complaints-about-math-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-while-true-learn-post-1-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/posts/while-true-learn/post-1.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-posts-while-true-learn-post-1-mdx" */),
  "component---src-templates-interests-jsx": () => import("./../../../src/templates/interests.jsx" /* webpackChunkName: "component---src-templates-interests-jsx" */),
  "component---src-templates-product-page-jsx-content-file-path-src-content-meditation-products-kids-activity-book-going-to-paris-index-mdx": () => import("./../../../src/templates/product-page.jsx?__contentFilePath=/opt/build/repo/src/content/meditation/products/kids-activity-book-going-to-paris/index.mdx" /* webpackChunkName: "component---src-templates-product-page-jsx-content-file-path-src-content-meditation-products-kids-activity-book-going-to-paris-index-mdx" */),
  "component---src-templates-product-page-jsx-content-file-path-src-content-meditation-products-physics-meditations-index-mdx": () => import("./../../../src/templates/product-page.jsx?__contentFilePath=/opt/build/repo/src/content/meditation/products/physics-meditations/index.mdx" /* webpackChunkName: "component---src-templates-product-page-jsx-content-file-path-src-content-meditation-products-physics-meditations-index-mdx" */),
  "component---src-templates-welcome-page-jsx-content-file-path-src-content-welcome-pages-physics-meditation-can-meditation-help-anxiety-mdx": () => import("./../../../src/templates/welcome-page.jsx?__contentFilePath=/opt/build/repo/src/content/welcome-pages/physics-meditation/can-meditation-help-anxiety.mdx" /* webpackChunkName: "component---src-templates-welcome-page-jsx-content-file-path-src-content-welcome-pages-physics-meditation-can-meditation-help-anxiety-mdx" */),
  "component---src-templates-welcome-page-jsx-content-file-path-src-content-welcome-pages-physics-meditation-travel-the-universe-mdx": () => import("./../../../src/templates/welcome-page.jsx?__contentFilePath=/opt/build/repo/src/content/welcome-pages/physics-meditation/travel-the-universe.mdx" /* webpackChunkName: "component---src-templates-welcome-page-jsx-content-file-path-src-content-welcome-pages-physics-meditation-travel-the-universe-mdx" */)
}

