import React from 'react';
//import {AnimatePresence} from 'framer-motion';
import {themeOptions} from '@styles/theme.js';
import { ThemeProvider, useTheme } from '@mui/material'

export const wrapPageElement = ({element}) =>  <ThemeProvider theme={themeOptions}>
{element}
</ThemeProvider>;

{/* <ThemeProvider theme={themeOptions}>
    <AnimatePresence mode='wait'>
      {element}
    </AnimatePresence>
</ThemeProvider> */}
/**
 * error skipped when AnimatePresence is present
 * Failed to remove .cache files. EBUSY: resource busy  
or locked, unlink 'H:\Code\gatsby\stimsims-website\.cache\caches-lmdb\data.mdb'
 */