import { createTheme, responsiveFontSizes } from '@mui/material';
// import "@fontsource/lato";
// import "@fontsource/libre-bodoni";
//import "@fontsource/yellowtail";

const FONTS_TEXT = 'Trebuchet, Verdana, Arial, sans-serif';
const FONTS_HEADINGS = 'Georgia; Times New Roman, serif';
const theme = createTheme({
  palette: {
    type: 'light',
    invert: {
      main: "#31084d",
      text: "#ffffff",
      neutral: '#252525',
      secondary: '#6b0728'
    },
    primary: {
      main: '#302798',
      secondary: '#19d5db',
      text: '#252525',
      neutral: '#ffffff', //'#ece8e5',
      neutralTranslucent: '#ffffffaa'
    },
    mybtn: {
      light: '#e88bc5',
      main: '#db1991',
      dark: '#991767',
      contrastText: 'rgba(255, 255, 255)',
    },
    clear: {
      light: 'rgba(255, 255, 255, 0.8)',
      dark: 'rgba(255, 255, 255, 0.4)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    background: {
      paper: 'white',
      default: '#e2e2e2'
    },
  },
  typography: {
    // Tell MUI what's the font-size on the html element is.
    htmlFontSize: 18,
    fontFamily: FONTS_TEXT,
    h1: {
      fontFamily: FONTS_HEADINGS,
      // [theme.breakpoints.up('md')]: {
      //   fontSize: '9rem',
      // },
    },
    h2: {
      fontFamily: FONTS_HEADINGS
    },
    h3: {
      fontFamily: FONTS_HEADINGS
    },
    h4: {
      fontFamily: FONTS_HEADINGS
    },
    h5: {
      fontFamily: FONTS_HEADINGS
    },
    h6: {
      fontFamily: FONTS_HEADINGS
    },
    subtitle: {
      fontFamily: FONTS_HEADINGS
    },
    body1: {
      fontWeight: 200,
      fontFamily: FONTS_TEXT
    },
    a: {
      fontFamily: FONTS_TEXT
    },
    body2: {
      fontFamily: FONTS_TEXT
    },
    // button: {
    //   fontStyle: 'italic',
    // },
  }
})

const withFonts = responsiveFontSizes(theme);
console.log(`material ui theme`, theme);
export const themeOptions = withFonts;

